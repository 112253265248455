<template>
    <div
        class="pa-4 d-flex justify-space-between"
    >
        <v-btn
            color="primary"
            dark
            depressed
            @click="upbit()"
        >
            upbit
        </v-btn>
    </div>
</template>

<script>
export default {
    data: () => ({
        
    }),

    methods: {
        // 초성 추출 함수
        getChosung(str) {
            const cho = ["ㄱ","ㄲ","ㄴ","ㄷ","ㄸ","ㄹ","ㅁ","ㅂ","ㅃ","ㅅ","ㅆ","ㅇ","ㅈ","ㅉ","ㅊ","ㅋ","ㅌ","ㅍ","ㅎ"]
            let result = ""
            for (let i = 0; i < str.length; i++) {
                const code = str.charCodeAt(i) - 44032
                if (code > -1 && code < 11172) {
                    result += cho[Math.floor(code / 588)]
                }
            }
            return result
        },

        async upbit() {
            try {
                // 모든 시장 정보를 가져옵니다
                const marketResponse = await this.$http.get('https://api.upbit.com/v1/market/all')
                const markets = marketResponse.data
                
                // KRW로 시작하는 시장만 필터링합니다
                const krwPairs = markets.filter(market => market.market.startsWith('KRW'))
                
                // KRW 페어의 현재가를 가져옵니다
                const marketCodes = krwPairs.map(pair => pair.market).join(',')
                const tickerResponse = await this.$http.get('https://api.upbit.com/v1/ticker', {
                    params: { markets: marketCodes }
                })
                const tickers = tickerResponse.data
                
                // DB에 저장할 데이터를 가공합니다
                tickers.map(ticker => {
                    const marketInfo = krwPairs.find(pair => pair.market === ticker.market)

                    this.$http.post('/api/coin/insert', {
                        params: {
                            code: ticker.market.replace('KRW-', ''),
                            name_en: marketInfo.english_name,
                            name_kr: marketInfo.korean_name,
                            chosung: this.getChosung(marketInfo.korean_name),
                            value: ticker.trade_price,
                            rate: 1, // 기본값 1로 설정
                            symbol: '', // API에서 제공하지 않음
                        }
                    }).then((res) => {
                        console.log(res)
                    })
                })
            } catch (error) {
                console.error('Error:', error)
            }
        }
    }
}

</script>