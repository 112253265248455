<template>
    <div
        :style="$vuetify.breakpoint.mobile? 'width:100%;':'width:440px; height: calc(100vh - 92px);'"
    >
        <!-- # 문구 -->
        <div
            v-if="!$store.state.client_user.is_logined"
            class="pt-1 mt-1 pl-5 mb-0"
            style="font-size:14px; font-weight:500; height:29px; color:#ef6d6d; cursor:pointer;"
            @click="$router.push('/auth/login')"
        >
            로그인을 해야 즐겨찾기 정보가 저장됩니다
        </div>

        <!-- # 검색 -->
        <div
            class="px-2 mt-0 mobile"
            :class="$store.state.client_user.is_logined? 'mt-2':''"
        >
            <v-text-field
                hide-details="auto"
                dense
                outlined
                class="rounded-15"
                color="primary"
                placeholder="검색"
                v-model="keyword"
                @input="debounceSearch()"
                @key.enter="debounceSearch()"
            >
                <template v-slot:prepend-inner>
                    <v-icon class="mr-1 mt-1px" size="18" color="#424361">mdi-magnify</v-icon>
                </template>
            </v-text-field>
        </div>


        <!-- # 목록 상단 -->
        <div
            class="d-flex alter_black--text text-body-2 mt-1 pb-2 mb-2"
            :style="{ paddingRight: scrollBarWidth + 'px' }"
            style="border-bottom:1px solid #e0e0e0; height:29px;"
        >
            <!-- # 국가 -->
            <div
                style="width:32%"
                class="pl-3"
            >
                {{ $store.state.list_tab == '암호화폐'? '이름' : '국가' }}
            </div>

            <!-- # 환율 -->
            <div
                v-if="$store.state.list_tab == '통화' || $store.state.list_tab == '암호화폐'"
                style="width:46%"
                class="pl-1 d-flex justify-space-between pr-4"
            >
                {{ $store.state.list_tab == '암호화폐'? '현재가' : '환율' }}
            </div>

            <!-- # 변동율 -->
            <div
                v-if="$store.state.list_tab == '통화' || $store.state.list_tab == '암호화폐'"
                style="width:26%"
                class="pl-0"
            >
                변동율
                <v-icon size="16" class="ml-2px mb-2px" @click="loadCurrencyList(); load();">mdi-reload</v-icon>
            </div>

            <!-- # 보기 방식 선택 (즐겨찾기) -->
            <div
                v-if="$store.state.list_tab == '즐겨찾기'"
                style="width:72%; margin-top:-8px;"
                class="d-flex pl-2"
            >
                <v-checkbox
                    style="width:113px;"
                    dense
                    hide-details
                    label="현재 환율"
                    v-model="is_exchange"
                ></v-checkbox>
                <v-checkbox
                    dense
                    hide-details
                    label="사용자지정 환율"
                    v-model="is_custom"
                ></v-checkbox>
            </div>
        </div>

        <!-- # 목록 (통화 & 암호화폐) -->
        <div
            v-if="($store.state.list_tab == '통화' || $store.state.list_tab == '암호화폐') && !loading"
            :style="$vuetify.breakpoint.mobile? 'height:78vh;':'height: calc(100% - 80px);'"
            style="overflow-y:scroll;"
        >
            <draggable
                v-if="!loading"
                v-model="list"
                ghost-class="ghost"
                @start="onStart"
                @end="onEnd"
                :disabled="is_search"
                :delay="150"
            >
                <div
                    v-for="item in is_search? search_list : list" :key="item.code"
                    class="list-item d-flex  alter_black--text text-body-2"
                    style="
                            cursor: grab; 
                            user-select: none; 
                            padding: 12px 8px;
                            position: relative;
                        "
                >
                    <!-- # 국가 -->
                    <div
                        style="width:32%; max-width:32%; overflow:hidden;"
                        class="pl-1 d-flex align-center"
                    >
                        <!-- 이미지 -->
                        <v-sheet
                            width="34"
                            height="34"
                        >
                            <!-- 통화 -->
                            <v-img
                                v-if="item.type == 'currency' && item.code"
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc; width:34px; height:34px;"
                                :src="require('@/assets/flags/'+item.code+'.svg')"
                            ></v-img>

                            <!-- 암호화폐 -->
                            <v-img
                                v-if="item.type == 'coin'"  
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc;"
                                :src="require('@/assets/coins/'+item.code+'.png')"
                            ></v-img>
                        </v-sheet>

                        <!-- 글자 -->
                        <div
                            class="ml-6px"
                            style="min-width: 0; overflow: hidden; max-width: calc(100% - 50px);"
                        >
                            <v-tooltip v-if="item.name_kr.length > nameLength" top color="primary" content-class="rounded-15" nudge-top="-12" close-on-click-outside touch>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        style="overflow: hidden;"
                                    >
                                        <p
                                            class="alter_black--text mb-0 text-truncate"
                                            style="letter-spacing:-0.4px;"
                                        >
                                            {{item.name_kr}}
                                        </p>
                                    </div>
                                </template>
                                <p
                                    class="white--text mb-0"
                                    style="letter-spacing:-0.4px;"
                                >
                                    {{item.name_kr}}
                                </p>
                            </v-tooltip>

                            <p
                                v-else
                                class="alter_black--text mb-0 text-truncate"
                                style="letter-spacing:-0.4px;"
                            >
                                {{item.name_kr}}
                            </p>

                            <!-- 코드명 -->
                            <p
                                class="alter_grey--text font-weight-bold mb-0"
                                style="margin-top:-2px;"
                            >
                                {{item.code}}
                            </p>
                        </div>
                    </div>

                    <!-- # 환율 -->
                    <div
                        v-if="item.type == 'currency'"
                        style="width:46%;"
                        class="pl-1 pr-2px custom_exchange2"
                    >
                        <div
                            v-if="item.code != 'KRW'"
                            class="mb-0 force-ltr"
                            :class="item.custom != 0? 'grey--text text--lighten-1 font-weight-regular':''"
                            style="font-size:12px;"
                        >
                            {{item.symbol}}{{format_currency_value(item.value).unit}} = ￦{{format_currency_value(item.value).integer}}<span style="font-weight:400; color:#888;">{{format_currency_value(item.value).decimal}}</span>
                        </div>
                        <div
                            v-if="item.code != 'KRW'"
                            class="d-flex"
                        >
                            <p
                                class="mb-0 pr-1"
                                :class="item.custom == 0? 'grey--text text--lighten-1 font-weight-regular':'primary--text'"
                                style="font-size:12px; letter-spacing:-0.4px;"
                            >
                                사용자지정 ₩
                            </p>
                            <v-text-field
                                style="width:80px;"
                                class="shrink ma-0 pa-0 custom-text-color"
                                solo
                                flat
                                dense
                                hide-details
                                :value="getFormattedCustom(item)"
                                @input="updateCustom(item, $event)"
                                @keydown="handleKeydown_number"
                            ></v-text-field>
                        </div>
                    </div>

                    <!-- # 거래가 -->
                    <div
                        v-if="item.type == 'coin'"
                        style="width:46%;"
                        class="pl-1 pr-2px custom_exchange2"
                    >
                        <div
                            class="mb-0 force-ltr"
                            :class="item.custom != 0? 'grey--text text--lighten-1 font-weight-regular':''"
                            style="font-size:12px;"
                        >
                            <span v-if="item.symbol">1{{item.symbol}} =</span>￦{{format_coin_value(item.value).integer}}<span style="font-weight:400; color:#888;">{{format_coin_value(item.value).decimal}}</span>
                        </div>
                        <div
                            class="d-flex"
                        >
                            <p
                                class="mb-0 pr-1"
                                :class="item.custom == 0? 'grey--text text--lighten-1 font-weight-regular':'primary--text'"
                                style="font-size:12px; letter-spacing:-0.4px;"
                            >
                                사용자지정 ₩
                            </p>
                            <v-text-field
                                style="width:80px;"
                                class="shrink ma-0 pa-0 custom-text-color"
                                solo
                                flat
                                dense
                                hide-details
                                :value="getFormattedCustom(item)"
                                @input="updateCustom(item, $event)"
                                @keydown="handleKeydown_number"
                            ></v-text-field>
                        </div>
                    </div>

                    <!-- # 변동율 -->
                    <div
                        style="width:26%"
                        class="pl-0 d-flex justify-end align-center pr-2px"
                    >
                        <v-chip
                            v-if="item.type == 'currency' && item.code != 'KRW'"
                            :color="calculatePercentChange(item.value, item.rate) < 0? '#60a5fa':'#f87171'"
                            small
                            style="margin-top:2px;"
                        >
                            <v-icon v-if="calculatePercentChange(item.value, item.rate) < 0" color="white" size="10" class="mr-1">mdi-triangle-down</v-icon>
                            <v-icon v-else color="white" size="10" class="mr-1">mdi-triangle</v-icon>

                            <span style="color:white; font-weight:300;">{{Math.abs(calculatePercentChange(item.value, item.rate)).toFixed(2)}}%</span>
                        </v-chip>

                        <v-chip
                            v-if="item.type == 'coin'"
                            :color="item.rate < 0? '#60a5fa':'#f87171'"
                            small
                            style="margin-top:2px;"
                        >
                            <v-icon v-if="item.rate < 0" color="white" size="10" class="mr-1">mdi-triangle-down</v-icon>
                            <v-icon v-else color="white" size="10" class="mr-1">mdi-triangle</v-icon>

                            <span style="color:white; font-weight:300;">{{Math.abs(item.rate).toFixed(2)}}%</span>
                        </v-chip>

                        <div
                            v-if="$store.state.client_user.is_logined"
                        >
                            <v-icon v-if="!item.is_bookmarked" size="20" class="ml-2" @click="item.is_bookmarked = true; add_bookmark(item.name_kr);">mdi-star-outline</v-icon>
                            <v-icon v-else size="20" class="ml-2" color="primary" @click="item.is_bookmarked = false; remove_bookmark(item.name_kr);">mdi-star</v-icon>
                        </div>
                        <div
                            v-else
                        >
                            <v-icon size="20" class="ml-2" @click="not_logined()">mdi-star-outline</v-icon>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>

        <!-- # 목록 (즐겨찾기) -->
        <div
            v-if="$store.state.list_tab == '즐겨찾기' && !loading"
            :style="$vuetify.breakpoint.mobile? 'height:78vh;':'height: calc(100% - 80px);'"
            style="overflow-y:scroll;"
        >
            <!-- # 한국 -->
            <!--
            <div
                v-for="item in oneList" :key="item.code"
                class="list-item d-flex px-2 alter_black--text text-body-2"
                style="
                        cursor: grab; 
                        user-select: none; 
                        padding: 12px 8px;
                        position: relative;
                    "
            >
                <!~~ # 국가 ~~>
                <div
                    style="width:32%; max-width:32%; overflow:hidden;"
                    class="pl-1 d-flex align-center"
                >
                    <!~~ 이미지 ~~>
                    <v-sheet
                        width="34"
                        height="34"
                    >
                        <!~~ 통화 ~~>
                        <v-img
                            v-if="item.type == 'currency' && item.code"
                            class="rounded-circle"
                            style="box-shadow:0px 0px 4px #ccc; width:34px; height:34px;"
                            :src="require('@/assets/flags/'+item.code+'.svg')"
                        ></v-img>

                        <!~~ 암호화폐 ~~>
                        <v-img
                            v-if="item.type == 'coin'"  
                            class="rounded-circle"
                            style="box-shadow:0px 0px 4px #ccc;"
                            :src="require('@/assets/coins/'+item.code+'.png')"
                        ></v-img>
                    </v-sheet>

                    <!~~ 글자 ~~>
                    <div
                        class="ml-6px"
                        style="min-width: 0; overflow: hidden; max-width: calc(100% - 50px);"
                    >
                        <v-tooltip top color="primary" content-class="rounded-15" nudge-top="-12" close-on-click-outside touch>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                    style="overflow: hidden;"
                                >
                                    <p
                                        class="alter_black--text mb-0 text-truncate"
                                        style="letter-spacing:-0.4px;"
                                    >
                                        {{item.name_kr}}
                                    </p>
                                </div>
                            </template>
                            <p
                                class="white--text mb-0"
                                style="letter-spacing:-0.4px;"
                            >
                                {{item.name_kr}}
                            </p>
                        </v-tooltip>

                        <!~~ 코드명 ~~>
                        <p
                            class="alter_grey--text font-weight-bold mb-0"
                            style="margin-top:-2px;"
                        >
                            {{item.code}}
                        </p>
                    </div>
                </div>

                <!~~ # 숫자 & 즐겨찾기 ~~>
                <div
                    style="width:70%;"
                    class="d-flex pl-0 pr-2px font-weight-medium align-center"
                >
                    <!~~ 숫자 ~~>
                    <div
                        class="flex-grow-1 pr-2"
                    >
                        <p class="text-right alter_black--text text-subtitle-1 font-weight-bold mb-1 force-ltr">
                            {{item.symbol}} 
                            <!~~ {{item.value}} ~~>
                            <!~~ {{$toComma($math.divide($store.state.result, item.value))}} ~~>
                            {{$store.state.result}}
                            <!~~ {{
                                $toComma(
                                    $math.divide(
                                        $math.bignumber($store.state.result || 0), 
                                        $math.bignumber(item.exchange_type == '현재 환율' ? (item.value || 0) : (item.custom || 0))
                                    ).toFixed(2)
                                ).split('.')[0]
                            }} ~~>
                            <span style="font-weight:500;">
                                <!~~ .{{
                                    $toComma(
                                        $math.divide(
                                            $math.bignumber($store.state.result || 0),
                                            $math.bignumber(item.value || 0)
                                        ).toFixed(2)
                                    ).split('.')[1]
                                }} ~~>
                            </span>
                        </p>
                    </div>

                    <!~~ 즐겨찾기 ~~>
                    <v-icon
                        size="20"
                        class="ml-2"
                        color="primary"
                    >
                        mdi-star
                    </v-icon>
                </div>
            </div>-->
            <draggable
                v-if="!loading"
                v-model="list"
                ghost-class="ghost"
                @start="onStart"
                @end="onEnd"
                :disabled="is_search"
                :delay="150"
            >
                <div
                    v-for="item in filteredList" :key="item.code"
                    class="list-item d-flex px-2 alter_black--text text-body-2 mt-"
                    style="
                        cursor: grab; 
                        user-select: none; 
                        padding: 12px 8px;
                        position: relative;
                    "
                >
                    <!-- # 국가 -->
                    <div
                        style="width:32%; max-width:32%; overflow:hidden;"
                        class="pl-1 d-flex align-center"
                    >
                        <!-- 이미지 -->
                        <v-sheet
                            width="34"
                            height="34"
                        >
                            <!-- 통화 -->
                            <v-img
                                v-if="item.type == 'currency' && item.code"
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc; width:34px; height:34px;"
                                :src="require('@/assets/flags/'+item.code+'.svg')"
                            ></v-img>

                            <!-- 암호화폐 -->
                            <v-img
                                v-if="item.type == 'coin'"  
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc;"
                                :src="require('@/assets/coins/'+item.code+'.png')"
                            ></v-img>
                        </v-sheet>

                        <!-- 글자 -->
                        <div
                            class="ml-6px"
                            style="min-width: 0; overflow: hidden; max-width: calc(100% - 50px);"
                        >
                            <v-tooltip v-if="item.name_kr.length > nameLength" top color="primary" content-class="rounded-15" nudge-top="-12">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        style="overflow: hidden;"
                                    >
                                        <p
                                            class="alter_black--text mb-0 text-truncate"
                                            style="letter-spacing:-0.4px;"
                                        >
                                            {{item.name_kr}}
                                        </p>
                                    </div>
                                </template>
                                <p
                                    class="white--text mb-0"
                                    style="letter-spacing:-0.4px;"
                                >
                                    {{item.name_kr}}
                                </p>
                            </v-tooltip>

                            <p
                                v-else
                                class="alter_black--text mb-0 text-truncate"
                                style="letter-spacing:-0.4px;"
                            >
                                {{item.name_kr}}
                            </p>

                            <!-- 코드명 -->
                            <p
                                class="alter_grey--text font-weight-bold mb-0"
                                style="margin-top:-2px;"
                            >
                                {{item.code}}
                            </p>
                        </div>
                    </div>

                    <!-- # 숫자 & 즐겨찾기 -->
                    <div
                        style="width:70%;"
                        class="d-flex pl-0 pr-2px font-weight-medium align-center"
                    >
                        <!-- 숫자 -->
                        <div
                            class="flex-grow-1 pr-2"
                        >
                            <p class="text-right alter_black--text text-subtitle-1 font-weight-bold mb-1 force-ltr">
                                {{item.symbol}} 
                                {{
                                    $toComma(
                                        $math.divide(
                                            $math.bignumber($store.state.result || 0), 
                                            $math.bignumber(item.exchange_type == '현재 환율' ? (item.value || 0) : (item.custom || 0))
                                        ).toFixed(2)
                                    ).split('.')[0]
                                }}
                                <span style="font-weight:500;">
                                    .{{
                                        $toComma(
                                            $math.divide(
                                                $math.bignumber($store.state.result || 0),
                                                $math.bignumber(item.value || 0)
                                            ).toFixed(2)
                                        ).split('.')[1]
                                    }}
                                </span>
                            </p>
                            <div
                                class="d-flex custom_exchange2 align-center"
                                style="margin-top:-2px;"
                            >
                                <v-radio-group
                                    row
                                    v-model="item.exchange_type"
                                    hide-details
                                    class="mt-0 mb-0 pt-0 pb-0 d-flex mx-0 px-0"
                                    style="height:20px; width:152px !important;"
                                >
                                    <v-radio
                                        style="width:98px;"
                                        dense
                                        hide-details
                                        :label="'₩ ' + $toComma(item.exchange)"
                                        value="현재 환율"
                                    ></v-radio>
                                    <v-radio
                                        style="width:20px;"
                                        dense
                                        hide-details
                                        value="사용자지정 환율"
                                        :label="'₩'"
                                        :class="{'active-radio': item.exchange_type === '사용자지정 환율'}"
                                    ></v-radio>
                                </v-radio-group>
                                <v-text-field
                                    :class="{
                                        'custom-text-color-grey': item.exchange_type == '현재 환율',
                                        'custom-text-color': item.exchange_type == '사용자지정 환율'
                                    }"
                                    style="position:relative; width:90px;"
                                    class="shrink ma-0 pa-0"
                                    solo
                                    flat
                                    dense
                                    hide-details
                                    placeholder="사용자지정"
                                    :value="getFormattedCustom(item)"
                                    @input="updateCustom(item, $event)"
                                    @keydown="handleKeydown_number"
                                ></v-text-field>
                            </div>
                        </div>

                        <!-- 즐겨찾기 -->
                        <div
                            v-if="$store.state.client_user.is_logined"
                        >
                            <!-- <v-icon v-if="!item.is_bookmarked" size="20" class="ml-2" @click="item.is_bookmarked = true; add_bookmark(item.name_kr);">mdi-star-outline</v-icon> -->
                            <v-icon size="20" class="ml-2" color="primary" @click="item.is_bookmarked = false; remove_bookmark(item.name_kr);">mdi-star</v-icon>
                        </div>
                        <div
                            v-else
                        >
                            <v-icon size="20" class="ml-2" @click="not_logined()">mdi-star-outline</v-icon>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>

        <!-- <v-icon
            size="36"
            class="d-flex mx-auto mt-6"
            color="alter_grey lighten-2"
        >
            mdi-cancel
        </v-icon>
        <p
            class="text-caption alter_grey--text text-center mt-2"
        >
            암호화폐 목록이 없습니다
        </p> -->
    </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    components: {
        draggable,
    },

    data: () => ({
        select_list: {
            translate: [
                "KR 한국어",
                "EN 영어"
            ]
        },

        drag: false,
        list: [],
        search_list: [],
        bookmark_list: [],

        translate: "KR 한국어",

        original_list: [],

        oneList: [
            {
                exchange_type: "현재 환율",
                custom: 0,
                type: "currency",
                flag: "kr.svg",
                chosung: "ㅎㄱ",
                name_kr: "한국",
                name_en: "Korea",
                code: "KRW",
                symbol: "￦",
                exchange: 1,
            },
        ],

        keyword: "",
        is_search: false,

        is_exchange : true,
        is_custom : true,
        searchTimeout: null,

        initialLoadCount: 20, // 처음 로딩할 아이템 수
        isLoadingMore: false, // 추가 로딩 상태
        fullList: [], // 전체 목록을 임시 저장할 변수
        fullSearchList: [], // 전체 검색 결과를 임시 저장할 변수
    }),

    computed: {
        scrollBarWidth() {
            return window.innerWidth - document.documentElement.clientWidth
        },

        filteredList() {
            const targetList = this.is_search ? this.search_list : this.list
            
            if (this.is_exchange && this.is_custom) {
                return targetList
            } else if (this.is_exchange) {
                return targetList.filter(item => item.exchange_type === "현재 환율")
            } else if (this.is_custom) {
                return targetList.filter(item => item.exchange_type === "사용자지정 환율") 
            } else {
                return targetList.filter(item => false)
            }
        },

        nameLength() {
            return this.$vuetify.breakpoint.width < 400 ? 4 : 5
        }
    },

    watch: {
        '$store.state.list_tab': {
            async handler(newValue) {
                this.keyword = ""

                // await this.loadCurrencyList()

                this.loading = true

                if (newValue == "통화") {
                    this.list = this.original_list.filter(e => e.type == "currency")
                } else if (newValue == "암호화폐") {
                    this.list = this.original_list.filter(e => e.type == "coin")
                } else if (newValue == "즐겨찾기") {
                    this.list = this.original_list.filter(e => this.bookmark_list.includes(e.name_kr))
                }

                this.loading = false
            },
            immediate: true
        },

        keyword(){
            if(this.keyword == ""){
                this.is_search = false
            }
        },

        is_exchange(){
            if(this.$store.state.list_tab == "즐겨찾기"){
                this.list = tempList.filter(e => this.bookmark_list.includes(e.name) && e.exchange_type == "현재 환율")
            }
        }
    },

    async mounted() {
        if(this.$store.state.rotate){
            this.$store.commit("update_rotate", false)
        }

        // # 통화 목록 불러오기
        await this.loadCurrencyList()

        await this.loadBookmark()
    },

    methods: {
        format_coin_value(value) {
            if (!value) return { integer: '0', decimal: '' }
            
            // 절대값으로 변환하여 자릿수 확인
            const absValue = Math.abs(value)

            // 정수가 0인 경우 특별 처리
            if (absValue < 1) {
                const formattedValue = value.toFixed(6)
                return {
                    integer: formattedValue,
                    decimal: ''
                }
            }
            
            // 소수점 자릿수 결정
            let decimals
            if (absValue >= 1000) {
                decimals = 0
            } else if (absValue >= 1) {
                decimals = 2
            } else {
                decimals = 6
            }
            
            // 숫자 포맷팅
            const formattedValue = value.toFixed(decimals)
            const [integerPart, decimalPart] = formattedValue.split('.')
            
            return {
                integer: this.$toComma(integerPart),
                decimal: decimalPart ? `.${decimalPart}` : ''
            }
        },

        format_currency_value(value) {
            if (!value) return { integer: '0', decimal: '' }
    
            // 절대값으로 변환하여 자릿수 확인
            const absValue = Math.abs(value)
            
            // 10 미만일 경우 100을 곱함
            const adjustedValue = absValue < 10 ? value * 100 : value
            const displayUnit = absValue < 10 ? '100' : '1'
            
            // 소수점 자릿수 결정
            let decimals = 2  // 기본 소수점 자릿수를 2로 고정
            
            // 숫자 포맷팅
            const formattedValue = adjustedValue.toFixed(decimals)
            const [integerPart, decimalPart] = formattedValue.split('.')

            // # 소수점 자리수를 항상 2자리로 유지 (추가)
            const paddedDecimal = decimalPart.padEnd(2, '0')
            
            return {
                integer: this.$toComma(integerPart),
                decimal: paddedDecimal ? `.${paddedDecimal}` : '',
                unit: displayUnit
            }
        },

        calculatePercentChange(oldValue, newValue) {
            if (oldValue === 0) return 0
            return ((newValue - oldValue) / Math.abs(oldValue)) * 100
        },

        // # 통화 목록 불러오기
        async loadCurrencyList() {
            await this.$http.post("/api/currency/select")
            .then(async(res) => {
                // # 한국 원화 데이터 생성
                const krwData = {
                    exchange_type: "현재 환율",
                    custom: 0,
                    type: "currency", 
                    chosung: "ㅎㄱ",
                    name_kr: "한국",
                    name_en: "Korea",
                    alias: "대한민국,코리아,남한,조선,고려",
                    code: "KRW",
                    symbol: "￦",
                    value: 1,
                    rate: 0,
                    is_bookmarked: false
                }

                // 통화 데이터에 기본값 추가
                const currencyData = res.data.map(item => ({
                    ...item,
                    exchange_type: "현재 환율",
                    custom: 0,
                    type: "currency",
                    is_bookmarked: false
                }))

                // 코인 데이터 가져오기
                const coinRes = await this.$http.post("/api/coin/select")
                
                // 코인 데이터에 기본값 추가 
                const coinData = coinRes.data.map(item => ({
                    ...item,
                    exchange_type: "현재 환율", 
                    custom: 0,
                    type: "coin",
                    is_bookmarked: false
                }))

                // 한국 원화, 통화, 코인 데이터 병합
                this.original_list = [krwData, ...currencyData, ...coinData]
            })
        },

        handleKeydown_number(event) {
            const allowedKeys = [
                '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '00',
                // ! '.', '%','K', 'M', 'B' 확인필요
            ]

            if (!allowedKeys.includes(event.key)) {
                event.preventDefault()
            }
        },

        getFormattedCustom(item) {
            // 숫자를 로컬 문자열로 변환하여 콤마를 포함시킵니다.
            return this.$toComma(item.custom)
        },

        updateCustom(item, value) {
            // 입력값에서 모든 콤마를 제거하고 숫자로 변환합니다.
            const numericValue = parseFloat(value.replace(/,/g, ''))
            item.custom = isNaN(numericValue) ? 0 : numericValue
        },

        // 목록 불러오기 메서드 수정
        async load() {
            this.loading = true
            this.is_search = false
            
            // 전체 목록 준비
            let tempList = this.original_list.map(item => {
                item.is_bookmarked = this.bookmark_list.includes(item.name_kr)
                return item
            }).sort((a, b) => b.is_bookmarked - a.is_bookmarked)

            // 현재 탭에 따라 필터링
            if(this.$store.state.list_tab == "통화") {
                tempList = await tempList.filter(e => e.type == "currency")
            } else if(this.$store.state.list_tab == "암호화폐") {
                tempList = await tempList.filter(e => e.type == "coin")
            } else if(this.$store.state.list_tab == "즐겨찾기") {
                tempList = await tempList.filter(e => this.bookmark_list.includes(e.name_kr))
            }

            // 전체 목록 저장
            this.fullList = tempList
            // 초기 20개만 list에 할당
            this.list = tempList.slice(0, this.initialLoadCount)
            
            this.loading = false

            // 3초 후 나머지 목록 추가
            setTimeout(() => {
                this.isLoadingMore = true
                this.list = this.fullList // list를 전체 목록으로 업데이트
                this.isLoadingMore = false
            }, 2000)
        },

        // 디바운스 적용된 검색 메서드
        debounceSearch() {
            if (!this.keyword) {
                this.is_search = false
                return
            }
            
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout)
            }
            this.searchTimeout = setTimeout(() => {
                this.search()
            }, 100) // 100ms 대기
        },

        // 검색 메서드 수정
        search() {
            // 검색어가 비어있으면 전체 리스트 보여주기
            if (!this.keyword) {
                this.is_search = false
                return
            }

            this.is_search = true
            const keyword = this.keyword.toLowerCase()
            
            // 현재 탭에 맞는 기본 필터 조건 설정
            let typeFilter
            if (this.$store.state.list_tab === "통화") {
                typeFilter = item => item.type === "currency"
            } else if (this.$store.state.list_tab === "암호화폐") {
                typeFilter = item => item.type === "coin"
            } else {
                typeFilter = item => this.bookmark_list.includes(item.name_kr)
            }

            // 전체 검색 결과 준비
            this.fullSearchList = this.original_list.filter(item => {
                const matchesType = typeFilter(item)
                const matchesSearch = 
                    item.name_en.toLowerCase().includes(keyword)
                    || item.name_kr.includes(keyword)
                    || (item.alias ? item.alias.includes(keyword) : false)
                    || item.chosung.includes(keyword)
                    || item.code.toLowerCase().includes(keyword)
                
                if (matchesType && matchesSearch) {
                    item.is_bookmarked = this.bookmark_list.includes(item.name_kr)
                    return true
                }
                return false
            })

            // 북마크 정렬
            if (this.fullSearchList.some(item => item.is_bookmarked)) {
                this.fullSearchList.sort((a, b) => b.is_bookmarked - a.is_bookmarked)
            }

            // 초기 20개만 표시
            this.search_list = this.fullSearchList.slice(0, this.initialLoadCount)

            // 3초 후 나머지 검색 결과 표시
            setTimeout(() => {
                this.isLoadingMore = true
                this.search_list = this.fullSearchList
                this.isLoadingMore = false
            }, 2000)
        },

        // 즐겨찾기 불러오기
        async loadBookmark(){
            await this.$http.post("/api/bookmark/select/specific", {
                params: {
                    user_id: this.$store.state.client_user.user_id
                }
            }).then(async(res) => {
                if(res.data.length){
                    this.bookmark_list = res.data.map(e => e.name)
                }
                await this.load()
            })
        },

        // 즐겨찾기 추가
        add_bookmark(name){
            this.bookmark_list.push(name)

            this.$http.post("/api/bookmark/insert", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    name: name
                }
            })
        },

        // 즐겨찾기 삭제
        remove_bookmark(name){
            this.bookmark_list = this.bookmark_list.filter(e => e != name)

            this.$http.post("/api/bookmark/delete", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    name: name
                }
            })

            this.loadBookmark()
        },

        not_logined(){
            this.$globalSnackbar.show("즐겨찾기 기능은 로그인 이후 이용 가능합니다")
        },

        // ! 드래그 시작 (검토 필요)
        onStart() {
            // this.isDragging = true
        },

        // ! 드래그 종료 (검토 필요)
        onEnd() {
            // this.isDragging = false
            // this.draggedElement = null
        }
    }
}
</script>
<style scoped>
/* # 언어변경 (v-select) */
::v-deep .mobile .v-select fieldset {
    height:40px;
    min-height: 33px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px;
    height: 33px;
}

::v-deep .mobile .v-select .v-select__selections {
    height: 33px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* # 검색 (v-text-field) */
::v-deep .mobile .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px;
    height: 33px;
    background: transparent !important;
}

/* # 사용자지정 (v-text-field) */
::v-deep .custom_exchange .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .custom_exchange .v-text-field .v-text-field__slot {
    font-size: 12px;
    height: 33px;
    margin-left:-12px;
    background: transparent !important;
}

/* # 드래그 관련 */
.list-item {
    padding: 6px 0;
    border-bottom:4px;
}

.ghost {
    position: relative;
}

.ghost::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: #0369A1;
    pointer-events: none;
}

::v-deep .text-field-transparent .v-input__slot {
    background: transparent !important;
}

/* # v-checkbox */
::v-deep .v-input--selection-controls.v-input--dense .v-label {
    margin-left:-6px;
    margin-bottom:2px;
    font-size: 12px
}

/* # v-radio */
::v-deep .v-radio .v-icon {
    font-size: 18px;    
}

::v-deep .v-radio .v-label {
    font-size: 12px;
    margin-left: -6px;
}

/* v-radio 내부 패딩 조절 */
::v-deep .v-radio {
    padding: 0;
}

/* v-radio ripple 효과 위치 조절 */
::v-deep .v-radio .v-input--selection-controls__ripple {
    width:20px;
    height:20px;
    left: -5px;
    top: calc(50% - 17px);
}
</style>

<style scoped>
/* # 사용자지정2 (v-text-field) */
::v-deep .custom_exchange2 .v-text-field {
    min-height: 16px;
    height: 16px;
    padding: 0;
    border: none;
}

::v-deep .custom_exchange2 .v-text-field fieldset {
    min-height: 0;
    height: 0;
    border: none;
}

/* 모바일 화면 (600px 이하) */
@media (max-width: 600px) {
    ::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
        width: 60px;
    }
}

/* 데스크톱 화면 (600px 초과) */
@media (min-width: 601px) {
    ::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
        width: 100px;
    }
}

::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
    margin-top:2px;
    font-size: 12px;
    height: 16px;
    line-height: 16px; /* 텍스트 라인 높이 조정 */
    margin-left: -14px;
    padding: 0;
    background: transparent !important;
}

::v-deep .custom_exchange2 .v-text-field .v-label {
    display: none; /* 라벨 숨기기 */
}

::v-deep .custom_exchange2 .v-text-field .v-input__control {
    min-height: 16px;
    height: 16px;
}

::v-deep .custom_exchange2 .v-text-field .v-input__slot {
    min-height: 16px;
    height: 16px;
}

/* 사용자 지정 입력 필드 글자색 */
::v-deep .custom-text-color input {
    color: #0369A1 !important;
}

::v-deep .custom-text-color-grey input {
    color: #898989 !important;
    font-weight: 400;
}
</style>

<style scoped>
/* 라디오박스 > 활성화시에만 라벨 색 변경 */
::v-deep .active-radio .v-label {
    color: #0369A1 !important;
}
</style>

<style scoped>
.bottom-half {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}
</style>

<style scoped>
.list-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 80%;
    height: 1px;
    background: #e0e0e0;
}
</style>